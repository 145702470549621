<template>
    <main>
      <!-- Form Filter -->
      <header class="mb-1">
        <b-row>
          <!-- Page Limit -->
          <b-col md="2" sm="4" class="my-1">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>
          <!-- / -->
          <!-- Sort By -->
          <b-col md="4" sm="6" class="my-1">
            <b-form-group
              label="Sort"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="sortBySelect"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  size="sm"
                  :options="sortOptions"
                  class="w-75"
                >
                  <template v-slot:first>
                    <option value="">-- none --</option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                  class="w-25"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- / -->
          <!-- Filter Search -->
          <b-col md="6" sm="2" class="my-1">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
              size="sm"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  size="sm"
                  class="w-75"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button size="sm" :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- / -->
        </b-row>
      </header>
      <!-- /form filter -->
      <b-table
      v-if="isAdminGudang"
        striped
        small
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="datas"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(no)="{ index }">
          {{ index + 1 }}
        </template>
        <template #cell(qrcode)="{ item }">
          <img v-if="item.qrcode" :src="item.qrcode" alt="Qr Code" style="width: 40px;" />
        </template>
        <template #cell(jenis)="{ item }">
          {{ item.jenis == 1 ? "TETAP" : "BHP" }}
        </template>
        <template #cell(stok)="{ item }">
          {{ getTotalStok(item) }}
        </template>
        <template #cell(harga_dasar)="{ item }">
          {{ formatRupiah(item.hawrga_dasar) }}
        </template>
        <template #cell(id_satuan)="{ item }">
          {{ item.satuan ? item.satuan.satuan : "-" }}
        </template>
        <template #cell(kategori)="{ item }">
          {{ item.kategori ? item.kategori.kategori : "-" }}
        </template>
        <template #cell(action)="{ item }">
          <section class="d-flex justify-content-center">
            <b-button
              size="sm"
              @click.prevent="$router.push(`/asset/detail/${item.id}`)"
              class="mr-1"
              variant="outline-info"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button
              v-if="allowUpdate()"
              :id="`edit-btn-${item.id}`"
              size="sm"
              @click.prevent="$router.push(`/asset/edit/${item.id}`)"
              class="mr-1"
              variant="outline-info"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
  
            <b-button
              v-if="allowDelete()"
              :id="`delete-btn-${item.id}`"
              size="sm"
              @click.prevent="deleteSingle(item.id)"
              class="mr-1"
              variant="outline-danger"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
            <b-tooltip :target="`delete-btn-${item.id}`">Hapus</b-tooltip>
          </section>
        </template>
      </b-table>
      <b-table
      v-if="!isAdminGudang"
        striped
        small
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="datas"
        :fields="fieldsOwner"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
      <template #cell(harga_dasar)="{ item }">
          Rp. {{ formatRupiah(item.harga_dasar) }}
        </template>
        <template #cell(no)="{ index }">
          {{ index + 1 }}
        </template>
        <template #cell(jenis)="{ item }">
          {{ item.jenis == 1 ? "TETAP" : "BHP" }}
        </template>
        <template #cell(harga_jual)="{ item }">
          {{
            item.harga_jual && item.harga_jual.length > 0
              ? formatRupiah(item.harga_jual[0].harga)
              : 0
          }}
        </template>
        <template #cell(stok)="{ item }">
          {{ getTotalStok(item) }}
        </template>
        <template #cell(kategori)="{ item }">
          {{ item.kategori ? item.kategori.kategori : "-" }}
        </template>
        <template #cell(action)="{ item }">
          <section class="d-flex justify-content-center">
            <b-button
              size="sm"
              @click.prevent="$router.push(`/asset/detail/${item.id}`)"
              class="mr-1"
              variant="outline-info"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button
              v-if="allowUpdate()"
              :id="`edit-btn-${item.id}`"
              size="sm"
              @click.prevent="$router.push(`/asset/edit/${item.id}`)"
              class="mr-1"
              variant="outline-info"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-tooltip :target="`edit-btn-${item.id}`">Edit</b-tooltip>
  
            <b-button
              v-if="allowDelete()"
              :id="`delete-btn-${item.id}`"
              size="sm"
              @click.prevent="deleteSingle(item.id)"
              class="mr-1"
              variant="outline-danger"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
            <b-tooltip :target="`delete-btn-${item.id}`">Hapus</b-tooltip>
          </section>
        </template>
      </b-table>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="datas.length"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </main>
  </template>
  <script>
  import {
    BCard,
    BFormCheckbox,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BFormSelect,
    BPagination,
    BTable,
    BButton,
    BRow,
    BCol,
    BTooltip,
  } from "bootstrap-vue";
  
  export default {
    props: {
      datas: {
        required: true,
      },
    },
    components: {
      BFormGroup,
      BFormSelect,
      BFormInput,
      BInputGroup,
      BInputGroupAppend,
      BPagination,
      BTable,
      BButton,
      BTooltip,
      BRow,
      BCard,
      BCol,
      BFormCheckbox,
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => ({ text: f.label, value: f.key }));
      },
    },
    data() {
      return {
        pageOptions: [10, 50, 100],
        sortBy: null,
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        perPage: 10,
        totalRows: this.datas.length,
        currentPage: 1,
        fields: [
          { key: "show_detail", label: "#" },
          { key: "no", label: "No" },
          { key: "qrcode", label: "QR Code" },
          { key: "kode", label: "Kode" },
          { key: "tanggal", label: "Tanggal Aset" },
          { key: "nama", label: "Nama Barang", sortable: true },
          { key: "stok", label: "Stok" },
          { key: "action", label: "#" },
        ],
        fieldsOwner: [
          { key: "no", label: "No" },
          { key: "jenis", label: "Jenis" },
          { key: "kategori", label: "Kategori" },
          { key: "nama", label: "Nama Barang", sortable: true },
          { key: "stok", label: "Stok" },
          { key: "kode", label: "Kode" },
          { key: "kategori.umur", label: "Umur" },
          { key: "volume", label: "Volume" },
          { key: "harga_dasar", label: "Harga Dasar", sortable: true },
          { key: "action", label: "#" },
        ],
      };
    },
    methods: {
      getTotalStok(item) {
        return item.stok.penyimpanan + item.stok.peminjaman
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      deleteSingle(id) {
        this.$swal({
          title: 'Anda yakin?',
          text: `Barang aset ini akan dihapus`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        })
        .then(async res => {
          if(res.value ) {
            const payload = {
              fungsi: 1,
              id
            }

            this.$emit('loading', true)
            await this.$store.dispatch('asset/save', [payload])
            this.$emit('loading', false)
            this.$emit('refreshData')
          }
        })
      },
    }
  };
  </script>
  